<template>
  <div>
    <div class="rtl">
      <v-card>
        <v-card-title>
          لیست کمپین ها
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="جست و جو"
              single-line
              hide-details
          ></v-text-field>
          <v-btn class="whiteButton" @click="showNew = !showNew">اضافه کردن</v-btn>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="campaigns"
            :search="search"
        >
          <template v-slot:item.operations="{ item }">
            <v-btn class="whiteButton" style="margin-left: 5px;min-width: 120px!important;"
                   @click="DeactivateCampaign(item.id)">غیر فعال سازی کمپین
            </v-btn>
            <v-btn v-if="item.financialLevelCampaignGiftType !== 'NoGift'" class="whiteButton" style="margin-left: 5px;min-width: 120px!important;"
                   @click="showDialogGift(item.financialLevelCampaignGiftCircumstance)">جزییات پاداش
            </v-btn>
            <v-btn class="whiteButton" style="margin-left: 5px;min-width: 120px!important;"
                   @click="showDialog(item.items)">نمایش آیتم ها
            </v-btn>
          </template>
          <template v-slot:item.isActive="{ item }">
            <span>{{ item.isActive ? 'فعال' : 'غیر فعال' }}</span>
          </template>
          <template v-slot:item.campaignFinishTimeAtUtc="{ item }">
            <span>{{ getPersianDate(item.campaignFinishTimeAtUtc) }}</span>
          </template>
          <template v-slot:item.campaignStartTimeAtUtc="{ item }">
            <span>{{ getPersianDate(item.campaignStartTimeAtUtc) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <Popup :show.sync="show" width="900px">
      <template slot="body">
        <v-toolbar
            color="primary"
            dark
            class="rtl"
        >
          لیست سطح بندی
        </v-toolbar>
        <v-card-text class="rtl" v-if="show">
          <slot name="body"></slot>
          <div class="row" style="margin-top:20px">
            <v-text-field
                v-model="financial[0].title"
                label="عنوان"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="financial[0].code"
                label="کد"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="financial[0].exchangeFeePercent"
                label="درصد"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="financial[0].totalLastMonthTransactions"
                label="کل معاملات 1 ماه گذشته"
                outlined
            ></v-text-field>
          </div>
          <div class="row" style="margin-top:20px">
            <v-text-field
                v-model="financial[1].title"
                label="عنوان"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="financial[1].code"
                label="کد"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="financial[1].exchangeFeePercent"
                label="درصد"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="financial[1].totalLastMonthTransactions"
                label="کل معاملات 1 ماه گذشته"
                outlined
            ></v-text-field>
          </div>
          <div class="row" style="margin-top:20px">
            <v-text-field
                v-model="financial[2].title"
                label="عنوان"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="financial[2].code"
                label="کد"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="financial[2].exchangeFeePercent"
                label="درصد"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="financial[2].totalLastMonthTransactions"
                label="کل معاملات 1 ماه گذشته"
                outlined
            ></v-text-field>
          </div>
          <hr style="border-top-color: white">
          <!--        <v-btn color="success" @click="UpdateDefaultDefinition()">ویرایش</v-btn>-->
        </v-card-text>
      </template>
    </Popup>
    <Popup :show.sync="dialogGift" width="900px">
      <template slot="body">
        <v-toolbar
            color="primary"
            dark
            class="rtl"
        >
          جزییات
        </v-toolbar>
        <v-card-text class="rtl" v-if="dialogGift">
          <slot name="body"></slot>
          <div class="row" style="margin-top:20px">
            <v-text-field
                v-model="giftAssetSymbol"
                label="رمز ارز"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="giftVolumeForEachEligibleSubsetUser"
                label="حجم هدیه برای هر کاربر زیرمجموعه واجد شرایط"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="minimumTotalCompletedOrderForEachEligibleSubsetUser"
                label="حداقل کل سفارش تکمیل شده"
                outlined
            ></v-text-field>
          </div>
        </v-card-text>
      </template>
    </Popup>
    <Popup :show.sync="showNew" width="900px">
      <template slot="body">
        <v-toolbar
            color="primary"
            dark
            class="rtl"
        >
          اضافه کردن کمپین
        </v-toolbar>
        <v-card-text class="rtl">
          <slot name="body"></slot>
          <v-row style="margin-top: 20px">
            <v-col cols="3">
              <span>عنوان</span>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  v-model="title"
                  label="عنوان"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <span>کد کمپین</span>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  v-model="campaignCode"
                  label="کد کمپین"
                  outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <span>تاریخ شروع</span>
            </v-col>
            <v-col cols="3">
              <satrex-date-picker v-model="campaignStartTimeAtUtc"></satrex-date-picker>
            </v-col>
            <v-col cols="3">
              <span>تاریخ پایان</span>
            </v-col>
            <v-col cols="3">
              <satrex-date-picker v-model="campaignFinishTimeAtUtc"></satrex-date-picker>
            </v-col>
          </v-row>
          <div class="row" style="margin-top:20px">
            <v-text-field
                v-model="newCampaigns[0].title"
                label="عنوان"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="newCampaigns[0].code"
                label="کد"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="newCampaigns[0].exchangeFeePercent"
                label="درصد"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="newCampaigns[0].totalLastMonthTransactions"
                label="کل معاملات 1 ماه گذشته"
                outlined
            ></v-text-field>
          </div>
          <div class="row" style="margin-top:20px">
            <v-text-field
                v-model="newCampaigns[1].title"
                label="عنوان"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="newCampaigns[1].code"
                label="کد"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="newCampaigns[1].exchangeFeePercent"
                label="درصد"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="newCampaigns[1].totalLastMonthTransactions"
                label="کل معاملات 1 ماه گذشته"
                outlined
            ></v-text-field>
          </div>
          <div class="row" style="margin-top:20px">
            <v-text-field
                v-model="newCampaigns[2].title"
                label="عنوان"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="newCampaigns[2].code"
                label="کد"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="newCampaigns[2].exchangeFeePercent"
                label="درصد"
                outlined
            ></v-text-field>
            <v-text-field
                v-model="newCampaigns[2].totalLastMonthTransactions"
                label="کل معاملات 1 ماه گذشته"
                outlined
            ></v-text-field>
          </div>
          <div  v-if="gift">
            <v-row>
              <v-col cols="5">
                <span>انتخاب رمز ارز</span>
              </v-col>
              <v-col cols="3">
                <select id="accounting_rial_coin_options" class="InputSelect" v-model="selectedAsset">
                  <option value="" selected disabled hidden>لطفا انتخاب کنید</option>
                  <option :value="asset.id" v-for="(asset,index) in assets" :key="index">{{ asset.persianTitle }}</option>
                </select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <span>حجم هدیه برای هر کاربر زیرمجموعه واجد شرایط</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    v-model="GiftVolumeForEachEligibleSubsetUser"
                    label="حجم هدیه برای هر کاربر زیرمجموعه"
                    outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <span>حداقل کل سفارش تکمیل شده</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    v-model="MinimumTotalCompletedOrderForEachEligibleSubsetUser"
                    label="حداقل کل سفارش تکمیل شده "
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="row" style="margin-top: 20px">
            <v-col cols="4">
              <v-btn color="success" @click="gift = !gift">{{!gift ? 'فعال کردن گیفت' : 'غیر فعال کردن گیفت'}}</v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn color="success" @click="AddCampaign()">اضافه کردن</v-btn>
            </v-col>
          </div>
        </v-card-text>
      </template>
    </Popup>
  </div>
</template>

<script>
import axiosApi from "@/axios";
import Vue from "vue";
import convertDate from "@/plugins/convertDate";
import getPersianDateTime from "@/plugins/getPersianDate";
import Popup from "@/components/Popup";

export default {
  components: {
    Popup
  },
  name: "index.vue",
  data() {
    return {
      headers: [
        {text: 'آی دی', value: 'id'},
        {text: 'نام کمپین', value: 'campaignTitle'},
        {text: 'کد', value: 'campaignCode'},
        {text: 'وضعیت', value: 'isActive'},
        {text: 'تاریخ شروع ', value: 'campaignStartTimeAtUtc'},
        {text: 'تاریخ پایان', value: 'campaignFinishTimeAtUtc'},
        {text: 'عملیات', value: 'operations'},
      ],
      financial: [],
      gift : false,
      newCampaigns: [
        {
          "title": "",
          "code": "",
          "exchangeFeePercent": 0,
          "totalLastMonthTransactions": 0,
        },
        {
          "title": "",
          "code": "",
          "exchangeFeePercent": 0,
          "totalLastMonthTransactions": 0,
        },
        {
          "title": "",
          "code": "",
          "exchangeFeePercent": 0,
          "totalLastMonthTransactions": 0,
        },
      ],
      show: false,
      showNew: false,
      campaigns: [],
      search: '',
      selectedAsset: '',
      title: '',
      campaignCode: '',
      GiftVolumeForEachEligibleSubsetUser: '',
      MinimumTotalCompletedOrderForEachEligibleSubsetUser: '',
      campaignStartTimeAtUtc: '',
      campaignFinishTimeAtUtc: '',
      assets : [],
      dialogGift : false,
      giftAssetSymbol : '',
      giftAssetId : '',
      giftVolumeForEachEligibleSubsetUser : '',
      minimumTotalCompletedOrderForEachEligibleSubsetUser : '',
    }
  },
  mounted() {
    this.GetCampaigns()
    this.getAssets()
  }
  ,
  methods: {
    showDialogGift(items){
      this.giftAssetId = items.giftAssetId
      this.giftAssetSymbol = items.giftAssetSymbol
      this.giftVolumeForEachEligibleSubsetUser = items.giftVolumeForEachEligibleSubsetUser
      this.minimumTotalCompletedOrderForEachEligibleSubsetUser = items.minimumTotalCompletedOrderForEachEligibleSubsetUser
      this.dialogGift = true
    },
    getAssets() {
      axiosApi().post('api/Manage/v1/Asset/GetList')
          .then(({data}) => {
            if (data['isSuccess'] === true)
              this.assets = data.data
          })
    },
    GetCampaigns() {
      axiosApi().post('api/Manage/v1/FinancialLevel/GetCampaigns')
          .then((result) => {
            this.campaigns = result.data.data
          })
    },
    getPersianDate(date) {
      return getPersianDateTime(date);
    },
    DeactivateCampaign(id) {
      axiosApi().post('api/Manage/v1/FinancialLevel/DeactivateCampaign', {
        id: id
      }).then(() => {
        Vue.$toast.open({
          message: 'کمپین با موفقیت غیر فعال شد',
          type: 'success',
          position: 'top-right'
        })
        this.GetCampaigns()
      })
    },
    showDialog(items) {
      console.log(items)
      this.financial = items
      this.show = true
    },
    AddCampaign() {
      if (this.gift){
        axiosApi().post('api/Manage/v1/FinancialLevel/AddCampaign', {
              title: this.title,
              campaignCode: this.campaignCode,
              campaignStartTimeAtUtc: convertDate(this.campaignStartTimeAtUtc),
              campaignFinishTimeAtUtc: convertDate(this.campaignFinishTimeAtUtc),
              items: this.newCampaigns,
              financialLevelCampaignGiftType : !this.gift ? 'NoGift' : 'BasedOnTotalCompletedOrdersForEachSubSetUser',
              circumstances : {
                "giftAssetId" : this.selectedAsset,
                "giftVolumeForEachEligibleSubsetUser" : parseInt(this.GiftVolumeForEachEligibleSubsetUser),
                "minimumTotalCompletedOrderForEachEligibleSubsetUser" : parseInt(this.MinimumTotalCompletedOrderForEachEligibleSubsetUser)
              }
            }
        ).then(({data}) => {
          if (data['isSuccess'] === true) {
            Vue.$toast.open({
              message: 'کمپین با موفقیت  ایجاد شد',
              type: 'success',
              position: 'top-right'
            })
            this.GetCampaigns()
            this.showNew = false
          } else {
            Vue.$toast.open({
              message: 'مقادیر ارسالی نا معتبر است',
              type: 'error',
              position: 'top-right'
            })
          }
        })
            .catch(() => {
              Vue.$toast.open({
                message: 'مقادیر ارسالی نا معتبر است',
                type: 'error',
                position: 'top-right'
              })
            })
      }else {
        axiosApi().post('api/Manage/v1/FinancialLevel/AddCampaign', {
              title: this.title,
              campaignCode: this.campaignCode,
              campaignStartTimeAtUtc: convertDate(this.campaignStartTimeAtUtc),
              campaignFinishTimeAtUtc: convertDate(this.campaignFinishTimeAtUtc),
              items: this.newCampaigns
            }
        ).then(({data}) => {
          if (data['isSuccess'] === true) {
            Vue.$toast.open({
              message: 'کمپین با موفقیت  ایجاد شد',
              type: 'success',
              position: 'top-right'
            })
            this.GetCampaigns()
            this.showNew = false
          } else {
            Vue.$toast.open({
              message: 'مقادیر ارسالی نا معتبر است',
              type: 'error',
              position: 'top-right'
            })
          }
        })
            .catch(() => {
              Vue.$toast.open({
                message: 'مقادیر ارسالی نا معتبر است',
                type: 'error',
                position: 'top-right'
              })
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
